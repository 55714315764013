const NotFound = () => {
  return (
          <div style={{ color: 'white', display: 'block', height: '80vh', fontSize: '4rem', width: '100vw', textAlign: 'center', alignContent: 'center' }}>
            404 - Page Not Found
          </div>
        
  );
}

export default NotFound;
